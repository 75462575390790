// 对新闻内容的布局，移动端和PC端都用这一个Card
.NewsItemContent {
  @apply relative  p-4;
  @apply md:min-h-[186px] md:p-5;
  .title {
    @apply mb-2 break-all text-base font-medium text-black line-clamp-2;
    @apply md:text-lg md:leading-7;
    @apply xl:text-xl;
  }
  .abstract {
    @apply mb-4 break-all font-['PingFangSC-Regular'] text-xs leading-[20px] text-black opacity-65 line-clamp-2;
    @apply md:mb-0 md:text-base md:leading-6;
  }
  .times {
    @apply text-xs leading-5 text-gray-30;
    @apply md:absolute md:bottom-[20px] md:left-[20px] md:text-sm md:leading-[1.375rem];
  }
}
.NewsCenterItemContent {
  @apply relative  p-4;
  @apply md:min-h-[186px] md:p-5;
  .title {
    @apply mb-2 break-all text-base font-medium text-black line-clamp-2;
    @apply md:text-xl md:leading-7;
  }
  .abstract {
    @apply mb-4 break-all font-['PingFangSC-Regular'] text-xs leading-[20px] text-black opacity-65 line-clamp-2;
    @apply md:mb-0 md:text-base md:leading-6;
  }
  .times {
    @apply text-xs leading-5 text-gray-30;
    @apply md:absolute md:bottom-[20px] md:left-[20px] md:text-sm md:leading-[1.375rem];
  }
}

.NewsItem {
  @apply mx-0 mb-3 w-full;
  @apply md:mb-5 md:w-1/3 md:cursor-pointer md:px-[10px];
  @apply lg:w-1/3;
}
.NewsItemWrap,
.NewsCenterItemWrap {
  @apply h-full overflow-hidden rounded-lg bg-white;
  @apply md:rounded-2xl;
}

@media (min-width: 1024px) {
  .NewsItemWrap,
  .NewsCenterItemWrap {
    // transition: all 300ms ease-in-out;
    &:hover {
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
}

.NewsItemImg,
.NewsCenterItemImg {
  @apply relative overflow-hidden;
}

.NewsCenterItemImg {
  .img-box {
    @apply md:h-full md:w-full;
  }
}
// 焦点信息的Card
.NewsCenterItem {
  @apply mx-0 mb-3 w-full;
  @apply md:mb-5 md:flex md:w-2/3 md:cursor-pointer md:flex-col md:px-[10px];
  .NewsCenterItemWrap {
    @apply md:flex-1;
  }
  .NewsCenterItemImg {
    @apply md:flex-1;
  }
  .placeholder {
    @apply pt-0;
    @apply md:pt-[37.9%];
  }
}

// 电脑屏幕才有img放大的动画效果
@media (min-width: 1024px) {
  .NewsItem:hover,
  .NewsCenterItem:hover {
    @apply transition-all;
    // box-shadow: 0 4px 12px 0 rgb(17 25 37 / 10%);
    .img-box {
      @apply scale-110;
    }
    .img-box::after {
      opacity: 0.15;
    }
  }
  .img-box {
    transition: all 0.3s ease-in-out;
  }
  .img-box::after {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
  }
}

// 布局容器组件
.container {
  @apply w-full;
}
.containerInner {
  @apply md:mx-[-10px];
  @apply flex flex-wrap self-stretch;
}
