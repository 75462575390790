.arrow-btn-box {
  @apply relative flex cursor-pointer items-center justify-center rounded border-0;
  @apply lg:rounded-lg lg:border lg:border-black-20;
  @apply h-[30px] w-[92px];
  @apply lg:h-[48px] lg:w-[138px];
  @apply hover:bg-[#f5f5f5] active:bg-[#ebebeb];
  .arrow-btn-wrap-box {
    @apply flex  items-center justify-center rounded border-0;
  }
  .arrow-btn-text {
    @apply pl-[5px] text-sm lg:pl-[6px] lg:text-xl;
  }
  .arrow-btn-arrow {
    @apply flex h-4 w-4 items-center justify-center lg:h-6 lg:w-6;
  }
}
.arrow-btn-box::before {
  @apply left-0 top-0 rounded-[12px] border border-black-20 lg:hidden lg:rounded-2xl;
  pointer-events: none;
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
