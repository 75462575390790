.title-link-box {
  @apply flex w-full items-center justify-between;
  .link-title {
    @apply text-lg font-medium leading-5;
    @apply lg:text-2sm lg:leading-10;
  }
  .link-action-box {
    @apply relative flex cursor-pointer items-center justify-center rounded border-0;
    @apply lg:rounded-md lg:border lg:border-black-20;
    @apply h-6 w-[74px];
    @apply lg:h-8 lg:w-24;
    @apply hover:bg-[#f5f5f5] active:bg-[#ebebeb];
    .link-action-text {
      @apply pl-[4px] text-xs lg:pl-[4px] lg:text-sm;
    }
    .link-action-arrow {
      @apply flex h-3 w-3 items-center justify-center lg:h-4 lg:w-4;
    }
  }
  .link-action-box:before {
    @apply left-0 top-0 rounded-[8px] border border-black-20 lg:hidden;
    content: '';
    position: absolute;
    width: 200%;
    height: 200%;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
