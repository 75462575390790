.player-icon-mask {
  @apply absolute bottom-0 left-0 right-0 top-0 bg-transparent;
  @apply flex items-center justify-center;
  .player-icon-box {
    @apply flex  items-center justify-center rounded-full;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}
