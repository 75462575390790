.home-banner-box {
  @apply relative bg-[length:auto_260px] bg-[center_60px] bg-no-repeat lg:bg-[length:auto_400px] lg:bg-[center_top];
  background-image: var(--auto-bg);
  .home-banner-content {
    // @apply m-auto flex w-full max-w-screen-xl flex-col items-center px-5 pb-0 text-center;
    .content-main-title {
      @apply pt-12 text-center text-2xs font-medium leading-6 text-black-dark;
      @apply lg:pt-15 lg:text-3xsm lg:leading-[72px];
    }
    .content-assist-title-box {
      @apply mt-7 text-center text-sm;
      @apply lg:mt-[32px] lg:text-2xs;
      line-height: 1;
      .content-assist-title {
        @apply block lg:inline-block;
        color: rgba(17, 25, 37, 0.85);
        line-height: 1;
      }
    }
    .content-paragraph-top-box {
      @apply pt-3;
      @apply lg:pt-4;
    }
    .content-paragraph-bottom-box {
      @apply pb-[27px] pt-[8px];
      @apply lg:pb-[24px] lg:pt-0;
    }

    .content-paragraph-box {
      @apply m-auto w-[317px] text-center;
      @apply lg:w-full;

      .content-paragraph {
        @apply text-xs leading-5 text-black-65;
        @apply lg:inline-block lg:text-sm;
      }
    }

    .show-info-btn-box {
      @apply flex items-center justify-center pb-[30px]  pt-6 lg:hidden;
      .show-info-btn {
        @apply text-xs leading-5;
      }
      .show-info-arrow {
        @apply flex h-4 w-4 rotate-90 items-center justify-center;
      }
    }

    .downloadApp {
      @apply relative hidden text-center;
      @apply lg:block lg:pb-[54px] lg:pt-0;
      .downloadAppText {
        .downloadAppIcon {
          @apply mr-1;
        }
        color: rgba(17, 25, 37, 0.85);
        @apply m-auto flex w-fit cursor-pointer items-center text-2xs font-medium leading-8;
      }

      /* 弹框动画相关 */
      .downloadAppPop {
        @apply absolute;
        // top: 50px;
        // transform: scale(1.2);
        left: calc(50% - 446px);
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s ease-out;
      }
      .showPop {
        // transform: scale(1);
        opacity: 1;
        top: 42px;
        visibility: visible;
        transition: all 0.2s ease-in;
      }
      .downloadAppPic {
        @apply absolute h-[138px] w-[138px];
        top: 54px;
        left: calc(50% - 69px);
        z-index: 10;
        opacity: 0;
        visibility: hidden;
        // transform: scale(0.8);
        transition: all 0.2s ease-out;
      }
      .showPic {
        opacity: 1;
        visibility: visible;
        // transform: scale(1);
        transition: all 0.2s ease-in;
      }
    }
  }

  .comma-box {
    @apply hidden;
    @apply font-medium lg:inline-block lg:text-2xs;
  }

  .home-banner-bg,
  .home-banner-hover-bg {
    @apply absolute h-[25rem] w-full;
  }
}

.light {
  //color: #ffbe25;
  //padding: 0 4px;
  @apply px-[2px] lg:px-[4px];

  &.lightLeft {
    @apply pl-[4px] lg:pl-[8px];
  }
  &.lightRight {
    @apply pr-[4px] lg:pr-[8px];
  }
}

.bannerLeft {
  @apply absolute left-0 top-0 h-[399px] w-[320px] xl:h-[400px] xl:w-[400px];
  @apply bg-contain bg-center bg-no-repeat;
}

.bannerRight {
  @apply absolute right-0 top-0 h-[391px] w-[184px] xl:h-[400px] xl:w-[200px];
  @apply bg-contain bg-right bg-no-repeat;
}

@media screen and (min-width: 1024px) {
  .decoration,
  .home-banner-box {
    background-image: var(--1024-bg);
  }
  .home-banner-hover-box {
    background-image: var(--1024-hover-bg);
  }
}

@media screen and (min-width: 1280px) {
  .decoration {
    background-image: var(--1280-bg);
  }
}
